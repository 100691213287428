<div class="container">
  <div class="row d-flex justify-content-center">
    <div class="modal-header form-title__top md-tt__top text-center" *ngIf="!isRegister">
      <span class="title" [ngClass]="!isRegister ? 'title-popup' : ''">Xác thực</span>
      <button type="button" class="close" (click)="dismiss()">X
      </button>
    </div>
    <div class="col-lg-12 otp">
      <div class="otp-container">
        <span class="title" *ngIf="isRegister">Xác thực</span>
        <div class="otp-container_form">
          <div class="form-group text-center">
            <span>Vui lòng nhập mã xác thực (OTP) đã được gửi đến
              số điện thoại <strong>(+{{phonePostal}}) {{phone}}</strong> hoặc Zalo</span>
          </div>
          <div class="form-group" *ngIf="note">
            <span>Chú ý: </span><span>{{note}}</span>
          </div>
          <div class="form-group">
            <input-number class="w-100" [(amount)]="otp" [isInteger]="true" [isSeparate]="false" [maxLength]="6" [id]="'otp'">
            </input-number>
          </div>
          <div class="form-group group-text">
            <span>Mã OTP sẽ hết hạn trong vòng {{countdown}}s</span>
            <a (click)="resendOTP()" *ngIf="countdown === 0" class="udl">Gửi lại OTP</a>
          </div>
          <div class="form-group">
            <button type="submit" class="btn btn-primary btn-login " [ngClass]="getClass()" [disabled]="!validate()"
              (click)="confirm()">XÁC THỰC</button>
            <span class="error-text text-center" *ngIf="isInvalidOTP && countdown != 0">
              <img src="../../../../assets/images/icon/notice-red.svg" alt="">
              OTP không hợp lệ. Vui lòng thử lại</span>
            <!-- XACTHUCDKY -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>