// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  urlBackEnd : "https://apis.fplan.dev.techland.link/agency/",
  urlUploadBackEnd : "https://apis.fplan.dev.techland.link/agency/agency/upload",
  portBackEnd : "",
  urlBackEndBlog: "https://blogapi.dev.fmarket.vn",
  capcha : "6LcbdE0UAAAAABxa9o8ACuOIwzcvw4Nx8GjCv952",
  organization: "https://institution.fplan.dev.techland.link",
  partner: "https://fplan.dev.techland.link",
  API_FMARKET: "https://api.fma.dev.techland.link/web",
  WEB_FMARKET: "https://fma.dev.techland.link/",
};
