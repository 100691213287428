import {AfterViewInit, Component, OnDestroy, OnInit, Input, Output, EventEmitter} from "@angular/core";
import { EventManagerService } from '../../service/event-manager.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Constant } from '../../constant';
import { ShortcutService } from "../../service/shortcut.service";
/**
 * declare Jquery
 * */
declare let $: any;

@Component({
  selector: 'confirm-popup',
  templateUrl: "./confirm-popup.html",
  styleUrls: ["./confirm-popup.scss"]
})

export class ConfirmPopupComponent {
    @Input() properties;
    @Input() header;
    @Input() message;
    @Input() buttonLabel = 'Đồng ý';
    @Input() buttonCancelLabel = 'Hủy';
    @Input() func: any;
    @Input() cancelFunc: any;
    @Input() isDismissAll: boolean;
    iconImage: any = './assets/images/icons/ic-warning.svg';
    @Output() result: EventEmitter<any> = new EventEmitter();
    Constant = Constant;
 
    config: any = {};
    constructor(
        public eventManger: EventManagerService,
        public activeModal: NgbActiveModal,
        public ngModal: NgbModal
    ) {
    }
    ngOnInit() {
    }
    argree() {
        this.dismiss();
        if (this.func) {
            this.func();
        } else this.result.emit(true);
    }
    cancel() {
        this.dismiss();
        if (this.cancelFunc) {
            this.cancelFunc();
        } else this.result.emit(false) 
    }
    dismiss() {
        ShortcutService.removeFuncEsc();
        if (!this.isDismissAll) {
            this.activeModal.dismiss();
        } else {
            this.ngModal.dismissAll();
        }
        this.result.emit(true);
    }
}
