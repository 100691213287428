import {NgModule, CUSTOM_ELEMENTS_SCHEMA} from "@angular/core";
import {CommonModule} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {RouterModule} from "@angular/router";
import {FilterPipe} from "./service/filterPipe";
import {FileUploadModule} from "ng2-file-upload";
import {Safe} from "./service/keep-html.pipe";
import { FooterComponent, NavbarComponent, } from './layouts';
import { TransformMoneyPipe } from "./pipe/transformMoney.pipe";
import { TransformCustomDatePipe } from "./pipe/transformCustomDate.pipe";
import { TransformPhonePipe } from "./pipe/transformPhone.pipe";
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { DropdownSearchComponent } from './components/dropdown-search/dropdown-search.component';
import { PublicViewImageComponent } from "./components/public-view-image/public-view-image";
import { ErrorTextComponent } from "./components/error-text/error-text.component";
import { NewOTPComponent } from "./components/otp/otp.component";
import { FileSizePipe } from "./service/filesize.service";
import { AutoCompleteComponent } from "./components/auto-complete/auto-complete.component";
import { PagingComponent } from "./components/paging/paging.component";
import { FilterStartEndDateComponent } from "./components/filter-start-end-date/filter-start-end-date";
import { TransformTranslatePipe } from "./pipe/transformTranslate.pipe";
import { TranslateModule } from "@ngx-translate/core";
import { InputMoneyComponent } from "./components/input-money/input-money";
import { InputPhoneComponent } from "./components/input-phone/input-phone";
import { InputNoteComponent } from "./components/input-note/input-note.component";
import { InputNumberComponent } from "./components/input-number/input-number";
import { InputDateComponent } from "./components/input-date/input-date";
import { CheckNumberDirective } from "./directive/number.directive";
import { ChartJSLineChartVerticalComponent } from "./components/chart-js-line-chart/chart-js-line-chart.component";
import { ChartJsPieChartComponent } from "./components/chart-js-pie-chart/chart-js-pie-chart.component";
// export function createTranslateLoader(http: HttpClient) {
//   return new TranslateHttpLoader(http, './assets/i18n/', '.json');
// }
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    FileUploadModule,
    HttpClientModule,
    TranslateModule,
    NgbModalModule,
  ],
  declarations: [
    FilterPipe,
    Safe, CheckNumberDirective,
    FooterComponent,
    NavbarComponent,
    TransformCustomDatePipe,
    TransformMoneyPipe, TransformTranslatePipe,
    TransformPhonePipe, FileSizePipe,
    PagingComponent,
    DropdownSearchComponent, AutoCompleteComponent,
    PublicViewImageComponent,
    ErrorTextComponent, FilterStartEndDateComponent,
    NewOTPComponent,
    InputMoneyComponent, InputPhoneComponent, InputNoteComponent, InputNumberComponent, InputDateComponent,
    ChartJSLineChartVerticalComponent, ChartJsPieChartComponent
  ],
  providers: [
  ],
  exports: [
    RouterModule,
    HttpClientModule,
    TranslateModule,
    NgbModalModule,
    FileUploadModule,
    FooterComponent, NavbarComponent,
    TransformCustomDatePipe, TransformMoneyPipe,  TransformTranslatePipe, FileSizePipe, Safe,  FilterPipe,
    PagingComponent, DropdownSearchComponent, AutoCompleteComponent, NewOTPComponent,
    ErrorTextComponent, FilterStartEndDateComponent,
    InputMoneyComponent, InputPhoneComponent, InputNoteComponent, InputNumberComponent, InputDateComponent,
    CheckNumberDirective,
    ChartJSLineChartVerticalComponent, ChartJsPieChartComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule{ }
