import {AfterViewInit, Component, OnDestroy, OnInit, Input, Output, EventEmitter} from "@angular/core";
import { Constant } from "../../constant";
import { EventManagerService } from '../../service/event-manager.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DateUtil } from 'src/app/common/utils/date.util';
/**
 * declare Jquery
 * */
declare let $: any;

@Component({
    selector: 'success-popup',
    templateUrl: "./success-popup.html",
    styleUrls: ["./success-popup.scss"]
})

export class SuccessPopupComponent implements OnInit, OnDestroy {
    public Constant = Constant;
    @Output() result: EventEmitter<any> = new EventEmitter();
    @Input() title: any;
    @Input() message: any;
    @Input() isOkButton: any;
    @Input() titleOk: any;
    @Input() func: any;
    @Input() iconImage: any = './assets/images/icons/success.png';
    @Input() isDismissAll: boolean;
    constructor(
        public eventManger: EventManagerService,
        public activeModal: NgbActiveModal,
        public ngModal: NgbModal
    ) {}
    ngOnInit() {
        // console.log('open toast: ', DateUtil.parseDate(Date.now(), 'hh:mm:ss'));
    }
    ngOnDestroy(): void {
        // console.log('close toast: ', DateUtil.parseDate(Date.now(), 'hh:mm:ss'));
    }
    dismiss() {
        if (!this.isDismissAll) {
            this.activeModal.dismiss();
        } else {
            this.ngModal.dismissAll();
        }
        this.result.emit(true);
        if (this.func) {
            this.func();
        }
    }
}
