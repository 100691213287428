import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-error-text',
  templateUrl: './error-text.component.html',
  styleUrls: ['./error-text.component.scss']
})
export class ErrorTextComponent {
  @Input() message: string = '';
  @Input() isDisplay: boolean = true;
}
