<div class="dropdown" [ngClass]="disabled ? 'disabled' : ''" [style.background-color]="backgroundColor">
  <input class="form-control" [readonly]="disabled" id="action{{id}}" type="text" placeholder="Nhập dữ liệu.." [(ngModel)]="name" 
    (click)="open()" (ngModelChange)="changeValue()"  [ngClass]="isError ? 'border-red' : ''">
  <div id="{{id}}" class="dropdown-content" [ngClass]="openDropdownlist ? 'show' : ''">
    <input class="form-control input-search" type="text" placeholder="Search.." [(ngModel)]="textSearch" (keyup)="filter()">
    <div class="list-item">
      <a class="record" *ngFor="let item of list" [ngClass]="item.isHide ? 'hide' : ''" (click)="chooseValue(item)">
        <img *ngIf="item.icon" [src]="item.icon" height="25" style="margin-right: 10px;">
        <span >{{item[field]}}</span>
      </a>
    </div>
  </div>
</div>