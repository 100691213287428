import { Injectable } from '@angular/core';
import { Actions, Store } from '@ngxs/store';
import { BaseQueries } from './base/base.queries';
import { AppStateModel } from './app.state';

@Injectable()
export class AppQueries  extends BaseQueries {
  readonly states$ = this.store.select(state => (state['App'] as AppStateModel).states);
  readonly token$ = this.store.select(state => (state['App'] as AppStateModel).token);
  readonly theme$ = this.store.select(state => (state['App'] as AppStateModel).theme);
  readonly sourceInfos$ = this.store.select(state => (state['App'] as AppStateModel).sourceInfos);
  readonly products$ = this.store.select(state => (state['App'] as AppStateModel).products);
  readonly userProfile$ = this.store.select(state => (state['App'] as AppStateModel).userProfile);
  constructor(
    private store: Store,
    protected actions$: Actions
  ) { super(actions$); }
}
