<!-- Modal content-->
<div class="modal-contents">
  <div class="modal-body">
    <div class="info-account">
      <div class="modal-body-title">
        <img [src]="iconImage" class="image-success" />
        <h4 class="popup-title mt-3 mb-3">
          {{title || 'Thành công' | translate}}
        </h4>
      </div>
      <h6 class="popup-message" [innerHTML]="message"></h6>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-ok common__btn" (click)="dismiss()">
      {{titleOk || 'Đóng' | translate}}
    </button>
  </div>
</div>
