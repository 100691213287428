import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { Constant } from "../../constant";
import { EventManagerService } from "../../service/event-manager.service";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
/**
 * declare Jquery
 * */
declare let $: any;

@Component({
  selector: "warning-popup",
  templateUrl: "./warning-popup.html",
  styleUrls: ["./warning-popup.scss"],
})
export class WarningPopupComponent implements OnInit {
  public Constant = Constant;
  @Output() result: EventEmitter<any> = new EventEmitter();
  @Input() message: any;
  @Input() title: any;
  @Input() titleOk: any;
  @Input() titleCancel: any;
  @Input() func: any;
  @Input() cancelFunc: any;
  @Input() iconImage: any = "./assets/images/icons/ic-warning.svg";
  @Input() isDismissAll: boolean;
  constructor(
    public eventManger: EventManagerService,
    public activeModal: NgbActiveModal,
    public ngModal: NgbModal
  ) {}
  ngOnInit() {}
  dismiss() {
    if (!this.isDismissAll) {
      this.activeModal.dismiss();
    } else {
      this.ngModal.dismissAll();
    }
  }
  cancel() {
    this.dismiss();
    if (this.cancelFunc) {
      this.cancelFunc();
    } else this.result.emit(false);
  }
  confirm() {
    this.dismiss();
    if (this.func) {
      this.func();
    } else this.result.emit(true);
  }
}
