<div class="filter_start_end_date" [formGroup]="formDate">
  <div class="title" *ngIf="title">{{title}}</div>
  <div class="tb-padding--right">
    <div class="form-group">
      <div class="d-flex align-items-center customize customize__label">
        <div id="start-date" class="input-group d-flex flex-column">
          <form class="start-date">
            <div class="input-group">
              <span class="input-group_text">Từ ngày:</span>
              <div [ngClass]="!formDate.value.startDate ? 'no-data' : 'has-data'">
                <input-date [value]="formDate.value.startDate" 
                  (result)="emitInputDate('startDate', $event)" [isNoFuture]="true"
                  [placeholder]="'Từ ngày: dd/mm/yyyy'">
                </input-date>
              </div>  
           
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <span class="ic__arr">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
    >
      <path
        d="M7 14H21M21 14L14 7M21 14L14 21"
        stroke="#828282"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </span>
  <div class="tb-padding--right tb-padding--left">
    <div class="form-group">
      <div class="d-flex align-items-center customize customize__label">
        <div id="end-date" class="input-group d-flex flex-column">
          <form class="end-date">
            <div class="input-group">
              <span class="input-group_text">Đến ngày:</span>
              <div [ngClass]="!formDate.value.endDate ? 'no-data' : 'has-data'">
                <input-date [value]="formDate.value.endDate" 
                  (result)="emitInputDate('endDate', $event)" [isNoFuture]="true"
                  [placeholder]="'Đến ngày: dd/mm/yyyy'">
                </input-date>
              </div>
           
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
