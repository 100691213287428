<div class="modal-content">
  <div class="modal-body">
    <div class="info-account">
      <div class="modal-body-title">
        <img [src]="iconImage" class="image-success" />
        <h4 class="popup-title mt-3 mb-3">
          {{title | translate}}
        </h4>
      </div>
      <p class="popup-message" [innerHTML]="message">
    </div>
  </div>
  <div class="modal-footer">
    <a class="btn common__btn common__btn--sub" (click)="cancel()"
      >{{(titleCancel || 'Đóng') | translate}}</a
    >
    <a class="btn btn-ok common__btn" (click)="confirm()" *ngIf="titleOk"
      >{{(titleOk || 'Xác nhận') | translate}}</a
    >
  </div>
</div>
