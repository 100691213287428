import { Directive, Input, TemplateRef, ViewContainerRef, Injector } from '@angular/core';


@Directive({
    selector: '[numberCheck]'
})
export class CheckNumberDirective {
    private value: any;
    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainerRef: ViewContainerRef) {
        
    }

    @Input()
    set numberCheck(value: any) {
        this.value = value;
        let result = true;
        if (this.value === null) {
            result = false;
        }
        if (this.value === undefined) {
            result = false;
        }
        if (typeof this.value  !== 'number') {
            result = false;
        }
        this.viewContainerRef.clear();
        if (result) {
            this.viewContainerRef.createEmbeddedView(this.templateRef);
        }
    }
}
