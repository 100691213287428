<!--Pagination-->
<nav aria-label="pagination" *ngIf="total">
  <div class="pagination__flex">
    <span class="page__number">
      Số hàng hiển thị
      <select name="" id="" [(ngModel)]="size" (ngModelChange)="changeSize()">
        <option [value]="item.size" *ngFor="let item of sizes">{{item.name}}</option>
      </select>
      Hiện {{start}}-{{end}} trong số {{total}}
    </span>
    <ul class="pagination pg-blue" *ngIf="totalPage != 1">
      <!--Arrow left-->
      <li class="page-item" *ngIf="totalPage <= 5">
        <span class="page-link" (click)="totalPage > 1 && first()">
          Trang Đầu
        </span>
      </li>
      <li class="page-item" *ngIf="totalPage > 5" [ngClass]="selectedPage === 1 ? 'disabled-button' : ''">
        <span class="page-link" (click)="changePage(selectedPage - 5)">
          <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M3.24316 5.50049L8.74267 0.000976803L9.89441 1.15271L5.54661 5.50049L9.89441 9.84826L8.74267 11L3.24316 5.50049Z"
              fill="#828282" />
            <rect x="1.5" y="11" width="1.5" height="11" transform="rotate(-180 1.5 11)" fill="#828282" />
          </svg>
        </span>
      </li>
      <li class="page-item">
        <span class="page-link" [ngClass]="!checkPrevious() ? 'disabled-button' : ''" (click)="previous()">
          <svg xmlns="http://www.w3.org/2000/svg" width="7" height="11" viewBox="0 0 7 11" fill="none">
            <path
              d="M-2.40391e-07 5.50049L5.49951 0.000976803L6.65125 1.15271L2.30345 5.50049L6.65125 9.84826L5.49951 11L-2.40391e-07 5.50049Z"
              fill="#828282" />
          </svg>
        </span>
      </li>
      <li class="page-item" *ngFor="let item of pages; index as index">
        <span class="page-link" (click)="changePage(item)"
          [ngClass]="item === selectedPage ? 'active-link background-active-link' : ''">
          {{item}}
          <span class="sr-only">(current)</span>
        </span>
      </li>
      <li class="page-item">
        <span class="page-link" [ngClass]="!checkNext() ? 'disabled-button' : ''" (click)="next()">
          <svg xmlns="http://www.w3.org/2000/svg" width="7" height="11" viewBox="0 0 7 11" fill="none">
            <path
              d="M6.65137 5.50049L1.15186 11L0.0001197 9.84826L4.34792 5.50049L0.000119804 1.15271L1.15186 0.000976497L6.65137 5.50049Z"
              fill="#828282" />
          </svg>
        </span>
      </li>
      <li class="page-item" *ngIf="totalPage > 5" [ngClass]="selectedPage === totalPage ? 'disabled-button' : ''">
        <span class="page-link" (click)="changePage(selectedPage + 5)">
          <svg xmlns="http://www.w3.org/2000/svg" width="10" height="11" viewBox="0 0 10 11" fill="none">
            <path
              d="M6.65137 5.49951L1.15186 10.999L0.000119817 9.84729L4.34792 5.49951L0.000119437 1.15174L1.15186 -7.74048e-08L6.65137 5.49951Z"
              fill="#828282" />
            <rect x="8.39453" width="1.5" height="11" fill="#828282" />
          </svg>

        </span>
      </li>
      <li class="page-item" *ngIf="totalPage <= 5">
        <span class="page-link" (click)="totalPage > 1 && last()">
          Trang Cuối
        </span>
      </li>
    </ul>
  </div>
</nav>
<div class="text-center mt-3" *ngIf="total === 0">
  <img src="./assets/images/icons/icon-no-data.png">
</div>