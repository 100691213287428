<footer id="footer">
	<div class="container">
		<div class="row footer-bottom">
			<div class="col-12 text-center">
				<div class="btn-group dropup language float-left">
					<!-- <button class="btn btn-sm dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true"
						aria-expanded="false">
						Tiếng Việt
					</button> -->
					<!-- <div class="dropdown-menu"> -->
					<!-- <button class="dropdown-item" type="button">English</button>
					</div> -->
				</div>
				<p class="copyright small list-inline">©2024 FINCORP JSC. ALL RIGHTS RESERVED</p>

			</div>
		</div>
	</div>
	<!-- <span style="position: fixed; bottom: 35px; left: 28px; color: transparent;" (click)="openTest()">  Data Test</span> -->
</footer>