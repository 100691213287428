<div class="modal-content">
  <!-- Modal Header -->
  <div class="modal-header">
  </div>

  <!-- Modal body -->
  <div class="modal-body">
    <div class="modal-body-title mt-3 mb-3">
      <img [src]="iconImage" class="image-success" />
      <h4 class="modal-body-title-text">{{header || 'Xác nhận'}}</h4>
    </div>
    <div class="col-12 modal-body-message"  [innerHTML]="message">
    </div>
  </div>
  <div class="modal-footer">
    <div class="d-flex justify-content-end align-items-center">
      <a class="btn btn-close mr-4" (click)="cancel()">{{buttonCancelLabel}}</a>
      <button class="btn btn-ok common__btn" (click)="argree()">
        <span>  {{buttonLabel}}</span>
      </button>
    </div>
  </div>
</div>