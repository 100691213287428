import { Component, Input, Output, EventEmitter, AfterViewInit, OnChanges, SimpleChanges, TemplateRef, OnInit, OnDestroy } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { EventManagerService } from '../../service/event-manager.service';
declare let $;
@Component({
  selector: 'auto-complete',
  templateUrl: './auto-complete.component.html',
  styleUrls: ['./auto-complete.component.scss']
})
export class AutoCompleteComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @Input() title: string;
  @Input() list: any;
  @Input() disabled: any;
  @Input() isHaveContent = false;
  @Output() result: EventEmitter<any> = new EventEmitter();
  @Input() value: any = null;
  @Input() field: string  = 'name';
  @Input() key: string  = 'id';
  @Input() rowTemplate: TemplateRef<any>;
  @Input() multiple: any = false;
  @Input() isError: boolean = false;
  @Input() backgroundColor = 'transparent';
  @Input() minWidth = '120px';
  @Input() values: any[] = [];
  defaultItem: any = {id: undefined};
  @Input() isNoAll: any = true;
  name: any = '';
  textSearch: string;
  groups: any[] = [];
  openDropdownlist = false;
  eventSubscribers?: Subscription[] = [];
  emitValue$ = new Subject();
  id = 'myDropdown' + Math.floor(Math.random() * 1000) + Math.floor(Math.random() * 999);
  constructor(
    private eventManager: EventManagerService
  ) {

  }
  ngOnInit() {
    this.emitValue$.pipe(
      debounceTime(500),
      ).subscribe((event)=> { return this.result.emit(this.name);});
    this.eventSubscribers = [
      this.eventManager.subscribe('selected-item', (res) => {
        if (res.content) {
          this.chooseValue(res.content);
        }
      }),
      this.eventManager.subscribe('body-click', (res) => {
        const event = res.content.event;
        if ($(event.target).closest('#' + this.id).length === 0 && $(event.target).closest('#action' + this.id).length === 0) {
          this.openDropdownlist = false;
          document.getElementById(this.id).classList.remove("show");
        } else {
          this.openDropdownlist = true;
        }
      }),
    ];
  }
  ngAfterViewInit() {
    $('select').click(function (e) {
      e.preventDefault ();
    });
  }
  ngOnDestroy(): void {
    this.eventManager.destroys(this.eventSubscribers);
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes.list && changes.list.currentValue) {
      this.list = JSON.parse(JSON.stringify(changes.list.currentValue || []));
    }
    if (!this.value && this.value !== 0) { 
      this.name = this.title;
    } else {
      this.name = this.value;
    }
  }
  open() {
    document.getElementById(this.id).classList.toggle("show");
  }
  filter() {
    const filter = this.textSearch.toUpperCase();
    this.list.forEach((item) => {
      const text = (item[this.field] || '').toUpperCase();
      if (text.includes(filter) || !filter) {
        item.isHide = false;
      } else {
        item.isHide = true;
      }
    })
  }
  changeValue() {
    this.emitValue$.next(true);
  }
  chooseValue(value) {
    if (!this.multiple) {
      document.getElementById(this.id).classList.remove("show");
      this.value = value || {};
      this.name = this.value[this.field];  
    } else {
      const firstValue = this.values[0];
      if (firstValue && !firstValue.id) {
        this.values = this.list.filter((item) => item.isCheck);
      }
      const index = this.values.findIndex(item => item[this.key] === value[this.key]);
      if (index === -1) { 
        value.isCheck = true;
        if (!this.values.length && !value[this.key]) {
          this.values = [value];
          this.list.forEach((item) => item.isCheck = true);
        } else if (this.values.length && !value[this.key]) {
          this.values = [];
          this.list.forEach((item) => item.isCheck = false);
        } else if (value[this.key]) {
          this.values.push(value);
        }
      } else {
        value.isCheck = false;
        this.values.splice(index , 1);
      }
      this.name = this.values.slice(0,3).map(item => item[this.field]).join(', ') + `${this.values.length > 3 ? '...' : ''}`;
      this.name = this.name.substring(0, 20);
    }
    this.changeValue();
  }
}
